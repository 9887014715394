export const environment = {
  production:false,
  enableMsal: "#{enableMsal}#",

  host:'https://smart-supervision-qa.apps.ambientesbc.com',
  api: 'https://clientes-ext-qa.apps.ambientesbc.com/smart-supervision/adminrest/hmls/',
  apiProtected: 'https://clientes-ext-qa.apps.ambientesbc.com/smart-supervision/adminrest/**',
  time: "#{time}#",
  powerBi: "#{powerBi}#",

  azureConfig:{
    redirectUri: '#{redirectUri}#',
    clientId:  '#{clientId}#',
    tenantId:  '#{tenantId}#',
    resource: '#{resource}#'
  },

};

export const link_tablas_homologacion="https://www.superfinanciera.gov.co/publicaciones/10109446/industrias-supervisadasinteres-del-vigiladoreportesindice-de-reportes-de-informacion-a-la-superintendencia-financieratablas-anexas-para-el-reporte-de-informacion-10109446/"
export const link_web_service="https://www.superfinanciera.gov.co/publicaciones/15001/industrias-supervisadasinteres-del-vigiladoreportesindice-de-reportes-de-informacion-a-la-superintendencia-financieraguias-para-el-reporte-de-informaciondocumentos-tecnicos-15001/"
export const link_portal_sfc="https://smart.superfinanciera.gov.co/login"
